import { toast } from '.';
import * as Sentry from '@sentry/nextjs';

/**
 * @param {Promise<any> | (() => Promise<any>)} fn Async Function or Promise
 * @param {Boolean | string} successMsg  Default is `false`. Set `true` for default message or pass custom string msg.
 * @param {Boolean | string} errorMsg  Default is `true`. Set `true` for default message or pass custom string msg.
 * @param {Boolean} propagateError  Default is `true`. Set `false` to stop propagate Error from this function
 */
export const withDefaultUIHandler = async (
  fn,
  successMsg = false,
  errorMsg = false,
  propagateError = true
) => {
  try {
    const data = await (fn.then ? fn : fn());
    if (successMsg) {
      // console.log('Request Completed');
    }
    return data;
  } catch (error) {
    const errors = formatServerErrors(error);

    // Sentry.setExtra('error', error);
    Sentry.withScope((scope) => {
      let requestPayload = {}
      if(error?.config?.method == 'post') {
        requestPayload = error?.config?.data ? JSON.parse(error.config.data) : {}
      }
      Sentry.setExtras({ errors, error });
      scope.setFingerprint(errors)
      Sentry.captureException(error, {
        extra: {
          requestPayload,
        },
      });

    })


    // const isResourceLocked = error?.response?.status == 423; //resource locked , sent in case of user is not verified
    if (errorMsg !== false) {
      errors.forEach(error => {
        toast(error);
      });
    }
    if (propagateError) {
      throw error;
    }
  }
};

export function formatServerErrors(error) {
  const serverErrorMsg =
    error?.response?.data?.error || error?.response?.data?.err;

  if (serverErrorMsg && typeof serverErrorMsg === 'string') {
    return [serverErrorMsg];
  }

  if (error?.response) {
    const responseData = error.response.data;
    const statusCode = error.response.status;

    if (statusCode === 503 || statusCode === 502) {
      return ['Service down. Please try again.'];
    }

    if (responseData && typeof responseData === 'string') {
      return [responseData];
    }
    if ([404, 400, 500, 501, 405].includes(statusCode)) {
      return ['Some error occurred, please try again'];
    }

    if ([403, 401].includes(statusCode)) {
      return [`Forbidden. You're not allowed to perform the action`];
    }

    if (typeof responseData === 'string' && responseData.includes('Network')) {
      //"Network Error caught"
      if (window.navigator.onLine) {
        return ['Server Error'];
      }
      return ['Network Error or Service down'];
    }

    return ['Some error occurred, please try again'];
  }

  if (error.message && typeof error.message === 'string') {
    return !window.navigator.onLine
      ? ['No active internet connection!']
      : [error.message];
  }

  return ['Some error occurred, please try again'];
}
